import "bootstrap/dist/css/bootstrap.min.css";
import './assets/fonts/fonts.scss'
import './assets/styles/style.scss';

import Routes from "./router/Routes";
import { Suspense } from "react";
import { Provider } from "react-redux";
import store, { persistor } from './redux/store.js'
import { PersistGate } from "redux-persist/integration/react";
import FallbackLoader from "./components/common/ui/loader/FallbackLoader.js";
import AxiosLoaderComponent from "./components/common/ui/loader/LoaderComponent.js";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";


// const TRACKING_ID= "G-VGK164PNHV"   //for the https://delhi.transportstack.in
const TRACKING_ID= "G-GDVHSBN797"      //for the https://13.233.123.19/
// const TRACKING_ID = "G-TX8Y80CRGS"      //for the http://43.205.144.141/

ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<FallbackLoader />}>
            <AxiosLoaderComponent />
            <Routes />
          </Suspense>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
