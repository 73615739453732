import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import "../../assets/styles/product.scss";
import Tags from "./Tags";
import BusImage from '../../assets/images/products/bus.jpg';
import MetroImage from '../../assets/images/products/metro.png';
import PassengerCountImage from '../../assets/images/products/passengercount.png';
import ParkingImage from '../../assets/images/products/parking.png';
import RapidImage from '../../assets/images/products/rapidrail.png';
import ETAImage from '../../assets/images/products/eta.png';
import JourneyImage from '../../assets/images/products/journey.png';
import TicketingImage from '../../assets/images/products/ticket.png';

const Product = ({ data, onClick }) => {
  const [productImage, setProductImage] = useState('');
  useEffect(() => {
    if (data.name.includes('Parking')) {
      setProductImage(ParkingImage)
    } else if (data.name.includes('count')) {
      setProductImage(PassengerCountImage)
    } else if (data.name.includes('Metro')) {
      setProductImage(MetroImage)
    } else if (data.name.includes('Bus')) {
      setProductImage(BusImage)
    } else if (data.name.includes('ETA Calculator')) {
      setProductImage(ETAImage)
    } else if (data.name.includes('Journey')) {
      setProductImage(JourneyImage)
    } else if (data.name.includes('Ticketing')) {
      setProductImage(TicketingImage)
    }else {
      setProductImage(RapidImage)
    }
  }, []);

  return (
    <div className="product-card pt-4" onClick={onClick}>
      <Card className=" cursor-pointer">
        <Card.Img variant="top" alt={data.titleName} src={productImage} />
        <Card.Body>
          <Card.Title className="fw-bold" title={data.titleName}>
            {data.name}  {data.sub_name ? ' - ' + data.sub_name
              : ''}
          </Card.Title>
          <Card.Text>{data.description}</Card.Text>
          <Tags name={data.ovalCardData}></Tags>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Product;
